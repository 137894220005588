import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React, { Fragment } from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Block from '../../components/ui/Cards/Block';
import Carousel from '../../components/ui/Carousel/Carousel';
import PageBanner from '../../components/ui/PageBanners/PageBanner';
import Seo from '../../components/utils/Seo';
import { Direction } from '../../globals';
import './books-courses.scss';

interface BooksCoursesProps {
    data: {
        booksCoursesYaml: {
            booksCourses: BookCourse[];
        };
    };
}

const BooksCourses: React.FC<BooksCoursesProps> = ({
    data
}: BooksCoursesProps) => {
    const booksCourses = data.booksCoursesYaml.booksCourses;
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Books and Cources"
                url="books-courses/"
                description="All of our books and courses in one place"
            />
            <PageBanner className="books-courses-banner">
                <Fragment>
                    <h1>Books and Courses</h1>
                    <h3>Explore our courses and books</h3>
                </Fragment>
                <Fragment>
                    <div className="books-courses__carousel-wrapper">
                        <Carousel slidesNum={5}>
                            <Img
                                fluid={
                                    booksCourses[0].image.childImageSharp.fluid
                                }
                                alt={booksCourses[0].title}
                            ></Img>
                            <Img
                                fluid={
                                    booksCourses[1].image.childImageSharp.fluid
                                }
                                alt={booksCourses[1].title}
                            ></Img>
                            <Img
                                fluid={
                                    booksCourses[2].image.childImageSharp.fluid
                                }
                                alt={booksCourses[2].title}
                            ></Img>
                            <Img
                                fluid={
                                    booksCourses[3].image.childImageSharp.fluid
                                }
                                alt={booksCourses[3].title}
                            ></Img>
                            <Img
                                fluid={
                                    booksCourses[4].image.childImageSharp.fluid
                                }
                                alt={booksCourses[4].title}
                            ></Img>
                            <Img
                                fluid={
                                    booksCourses[5].image.childImageSharp.fluid
                                }
                                alt={booksCourses[5].title}
                            ></Img>
                        </Carousel>
                    </div>
                </Fragment>
            </PageBanner>

            <PageSection className="books-courses">
                <Container>
                    <Group size={2} className="books-courses__block">
                        <Block
                            direction={Direction.vertical}
                            className="books-courses__block__details"
                        >
                            <h3>{booksCourses[0].title} </h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: booksCourses[0].description || ''
                                }}
                            ></div>
                            <Link to={booksCourses[0].url}>Learn more</Link>
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="books-courses__block__image"
                        >
                            <Img
                                fluid={
                                    booksCourses[0].image.childImageSharp.fluid
                                }
                                alt={booksCourses[0].title}
                            ></Img>
                        </Block>
                    </Group>
                    <Group size={2} className="books-courses__block">
                        <Block
                            direction={Direction.vertical}
                            className="books-courses__block__image"
                        >
                            <Img
                                fluid={
                                    booksCourses[1].image.childImageSharp.fluid
                                }
                                alt={booksCourses[1].title}
                            ></Img>
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="books-courses__block__details"
                        >
                            <h3>{booksCourses[1].title}</h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: booksCourses[1].description || ''
                                }}
                            ></div>
                            <Link to={booksCourses[1].url}>Learn more</Link>
                        </Block>
                    </Group>
                    <Group size={2} className="books-courses__block">
                        <Block
                            direction={Direction.vertical}
                            className="books-courses__block__details"
                        >
                            <h3>{booksCourses[2].title}</h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: booksCourses[2].description || ''
                                }}
                            ></div>
                            <Link to={booksCourses[2].url}>Learn more</Link>
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="books-courses__block__image"
                        >
                            <Img
                                fluid={
                                    booksCourses[2].image.childImageSharp.fluid
                                }
                                alt={booksCourses[2].title}
                            ></Img>
                        </Block>
                    </Group>
                    <Group size={2} className="books-courses__block">
                        <Block
                            direction={Direction.vertical}
                            className="books-courses__block__image"
                        >
                            <Img
                                fluid={
                                    booksCourses[3].image.childImageSharp.fluid
                                }
                                alt={booksCourses[3].title}
                            ></Img>
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="books-courses__block__details"
                        >
                            <h3>{booksCourses[3].title}</h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: booksCourses[3].description || ''
                                }}
                            ></div>
                            <Link to={booksCourses[3].url}>Learn more</Link>
                        </Block>
                    </Group>
                    <Group size={2} className="books-courses__block">
                        <Block
                            direction={Direction.vertical}
                            className="books-courses__block__details"
                        >
                            <h3>{booksCourses[4].title}</h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: booksCourses[4].description || ''
                                }}
                            ></div>
                            <Link to={booksCourses[4].url}>Learn more</Link>
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="books-courses__block__image"
                        >
                            <Img
                                fluid={
                                    booksCourses[4].image.childImageSharp.fluid
                                }
                                alt={booksCourses[4].title}
                            ></Img>
                        </Block>
                    </Group>
                    <Group size={2} className="books-courses__block">
                        <Block
                            direction={Direction.vertical}
                            className="books-courses__block__image"
                        >
                            <Img
                                fluid={
                                    booksCourses[5].image.childImageSharp.fluid
                                }
                                alt={booksCourses[5].title}
                            ></Img>
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="books-courses__block__details"
                        >
                            <h3>{booksCourses[5].title}</h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: booksCourses[3].description || ''
                                }}
                            ></div>
                            <Link to={booksCourses[5].url}>Learn more</Link>
                        </Block>
                    </Group>
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export const fundamentalsQuery = graphql`
    {
        booksCoursesYaml {
            booksCourses {
                title
                description
                url
                image {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                }
            }
        }
    }
`;

export default BooksCourses;
